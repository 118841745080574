@import "~leaflet/dist/leaflet.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

.page-notfound {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.request-form-map-wrapper {
    position: absolute;
    z-index: 3;
    width: 100vw;
    height: 100vh;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.request-page-form {
    box-shadow: -10px 14px 10px #838282;
    border-right: 3px solid #d5d4d4;
    border-bottom: 3px solid #d5d4d4;
    border-left: 1px solid #d5d4d4;
    max-width: 40vw;
}

.request-page-form-txt-notice {
    color: #7e7d7d;
    font-family: Cambria, serif;
    font-style: italic;
    line-height: 31px;
}

.ant-picker:hover, .ant-picker-focused {
    /*border-color: unset !important;*/
    /*border-right-width: 0 !important;*/
    /*border-color: red;*/
    box-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    --tw-ring-inset: inset;
    --tw-ring-color: rgb(2 132 199);
}

